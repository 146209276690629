.generatingScheduleSendTestModal {
    width: 640px;
    min-width: 640px;
}

.title {
    font-weight: 300;
    font-size: 20px;
}

.form {
    margin-top: 40px;
}

.select {
    width: 100%;
    margin-top: 10px;
}

.label {
    color: #343A40;
    font-size: 14px;
}

.errorMessage {
    font-weight: 400;
    font-size: 12px;
    color: #ff7878;
}