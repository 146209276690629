.sidebarHeader {
  padding-top: 50px;
  padding-bottom: 35px;
  text-align: center;
  width: 100%;
  height: 165px;
}

.vaiLogo {
  height: 70px;
}

.vaiLogoSmall {
  height: 25px;
  margin-top: 25px;
}

a { 
  color: #787e82; 
  text-decoration: none;
} 
a:hover { color: #787e82 }

.sidebarList {
  padding: 0px 15px;
  /* width: 100%;
  height: 100%;
  overflow-y: scroll; */
}

.link {
  color: #787e82 !important;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 8px;
  height: 45px;
}

.help{
  margin-top: 16px;
}

a{
  color: #787e82 !important;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 8px;
  height: 45px;
}

.hideText {
  display: none;
}

.centerText {
  margin-left: 10px;
}

.activeLink {
  color: #fafafa !important;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 8px;
  height: 45px;
}

.link img {
  filter: invert(0.5);
}

.activeLink img {
  filter: brightness(0) invert(1);
}

.profileSection {
  position: absolute;
  bottom: 0;
}

.vaiLink {
  color: #787e82 !important;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 8px;
  height: 45px;
}

.vaiChevRight {
  position: absolute;
  right: 5%;
}

.vaiChevRightRotate {
  transform: rotate(96deg);
}

.sideBarListDiv{
  width: 100%;
  height: calc(100vh - 337px);
  overflow: auto;
}

.sidebarStateListDiv{
  width: 100%;
  height: calc(100vh - 420px);
  overflow: auto;
}

/* width */
.sidebarStateListDiv::-webkit-scrollbar {
  width: 5px !important;
}
