.fc-timegrid-axis{
 background-color: #0c212b;
 color:#fff;
 border: 1px solid white;
}
th.fc-timegrid-axis{
  width:53px;
 }
.fc-timegrid-slot-label-frame {
  color:#fff;
}
.fc-direction-ltr .fc-timegrid-slot-label-frame{
  text-align: center;
}
.fc-daygrid-event{
background-color: #6D6D73;
}
.fc-timegrid-event{
background-color: #fff;
}
.fc-timegrid-axis-cushion{
  text-align: center;
}
.fc-col-header-cell{
  background-color: #6D6D73;
}

.fc-col-header-cell-cushion{
  color:#fff;
  text-decoration: none;
}
.fc-event-main{
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.fc-toolbar-title{
  visibility: collapse;
}
.fc-today-button{
  visibility: collapse;
}
.fc-scrollgrid-sync-table tbody{
  height: 100%;
}
.fc-timegrid-event{
  /* min-height: 69px; */
  overflow: hidden;
}
thead>tr>th>div>.fc-scroller{
  overflow: auto !important;
}

a:hover {
  color: #fff;
}
.fc-v-event {
 border:none;
box-shadow: 0px 0.5px 2px 0px #00000040 !important;
}
.fc-button-primary{
  background-color: #008662 !important;
  border: #008662 !important;
  outline: none !important;
  box-shadow: none !important;
}
.fc-button-primary:focus{
  border:none !important;
  outline: none !important;
  box-shadow: none !important;
}
.fc-button-group:focus{
  border:none !important;
  outline: none !important;
  box-shadow: none !important;
}
.fc-button:focus{
  border:none !important;
  outline: none !important;
  box-shadow: none !important;
}
.fc-timegrid-slot{
  border:none !important;
}
.fc .fc-timegrid-body {
  background-color: #E2E8F0;
}
.fc-timegrid-slots > table >tbody>tr{
  height: 50px;
}