.loadingHeight {
  height: calc(100vh - 250px);
}
.divTableHeight {
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 250px);
}

.divTableHeight::-webkit-scrollbar-button {
  display:none;
}

.divTableHeight::-webkit-scrollbar-thumb {
  background-color: #cbcbcb;
  box-shadow: inset 0 0 0 2px #ffffff;
  border-right: 1px solid #dee3eb;
}

.divTableHeight::-webkit-scrollbar-track {
  border-right: 1px solid #dee3eb;
}

.divTableHeight::-webkit-scrollbar {
  background-color: #ffffff;
  width: 15px;
  padding: 5px;
}

.allDayLabel{
  color:#fff;
}
.allDayScore{
  color:#fff;
  float:right;
}

.calendarHeight {
  height: calc(100vh - 250px);
  overflow: scroll;
}

.saveButton {
  height: 36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 100;
  padding: 11px 24px;
  color: #ffffff;
  background-color: #008662;
  border: none;
  border-radius: 4px;
  /* margin-right: 25px; */
}
.saveButton:disabled {
  background-color: #b1b1b1;
cursor: not-allowed;
}

.cancelButton {
  height: 36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 100;
  padding: 11px 24px;
  background-color: #f6f6f6;
  color: #5c636a;
  border: none;
  border-radius: 4px;
}

.divScheduleScores{
  color: #6D6D73;
  position: absolute;
  margin-top: 10px;
  width: calc(100vw - 440px );
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lblTitleCalendar{
  /* white-space: nowrap; */
  overflow: hidden;
  display: block;
  line-height: 15px;
  min-height: 15px;
  max-height: 30px;
  text-overflow: ellipsis;
  font-size: 14px;
  width:100%; 
}

.td
{
  width: 200px;
}

.tdID {
  width: 100px;
}


.lblTitleCalendarNoWrap{
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  font-size: 14px;
  width:122px;
}

.lblTimeStampCalendar{
  color: #6D6D73;
  font-size: 12px;            
  width: 150px;        
}

.lblTimeStampCalendarNoWrap{
  color: #6D6D73;
  font-size: 12px;
  width: 122px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.divParent{
  position:relative;
   height:100%;
   margin-left:15px;
}

.divChild{
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
}
.faCircleNotchSpin {
  font-size: 50px;
  color: #13ad83;
}
.tableColumnNumber{
  text-align: right;
}

.scheduleTr {
  border-bottom: 1px solid #E2E8F0;
}

.scheduleTr:nth-child(even) td {
  background: #f2f2f2;
}

.scheduleTr td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  padding: 14px 10px;
  font-size: 12px;
  font-weight: 300;
  border-left: 0.50px #E2E8F0 solid;
  border-right: 0.50px #E2E8F0 solid;
  border-bottom: 0.50px #E2E8F0 solid;
  width: 100%;
}

.scheduleTr > td > img {
  filter: brightness(0) saturate(100%) invert(25%) sepia(57%) saturate(6445%) hue-rotate(159deg) brightness(96%) contrast(104%);
}

.scheduleColumn {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}