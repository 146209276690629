.dateInput {
    background-color: #ffffff;
    width: 100%;
}

.dateInputWrapper {
    display: flex;
    width: 100%;
    width: 100px;
    color: #6D6D73;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    padding: 7px 10px;
    overflow: hidden;
    width: 100%;
}

.lgDateInput {
    padding: 7.5px 10px !important;
}

.dateInputWrapper:hover {
    cursor:text;
}

.dayInput {
    text-align: end;
    width: 19px;
    padding-right: 1px;
}

.dayInput:placeholder-shown {
    width: 22px;
}

.monthInput {
    width: 18px;
    padding-left: 1px;
    padding-right: 1px;
}

.monthInput:placeholder-shown {
    width: 23px !important;
}

.yearInput {
    width: 35px;
    padding-left: 1px;
}

.dayInput, .monthInput, .yearInput {
    border: none;
    color: #343a40;
    font-size: 12px;
}

.dayInput:focus, .monthInput:focus, .yearInput:focus {
    outline: none;
}

.dayInput::placeholder, .monthInput::placeholder, .yearInput::placeholder {
    color: #B1B1B1;
}

.inputDivider {
    font-size: 10px;
    margin-top: 2px;
}

.invalidInput {
    border-color: #FF7878;
}

.errorMessage {
    color: #FF7878;
    font-size: 12px;
    margin-top: 3px;
    display: block;
}

.disabledInput,
.disabledInput .dayInput,
.disabledInput .monthInput,
.disabledInput .yearInput {
    border-color: #CED4DA;
    background-color: #CED4DA;
    color: #B1B1B1;
    cursor: not-allowed !important;
}