.authentication {
    width: 100%;
    height: 295px;
    background-color: #FFFFFF;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 17px;
    overflow-x: auto;
}

.title {
    font-weight: 300;
    font-size: 20px;
}

.form {
    margin-top: 30px;
}

.inputForm {
    margin-top: 10px;
    width: 100%;
}

.inputLabel {
    font-size: 14px;
    line-height: 17px;
    color: #343a40;
}

.passwordInputField,
.bodyInput {
    width: 100%;
    padding: 8px 13px;
    color: #343a40;
    font-size: 12px;
    line-height: 14.32px;
    border-radius: 4px;
    border: 1px solid #d3d2d2;
}

.bodyInput {
    resize: none;
    height: 95px;
}

.passwordInputField {
    border-right: none;
    border-radius: 4px 0px 0px 4px;
}
  
.passwordInputField::placeholder,
.bodyInput::placeholder {
    color: #b1b1b1;
}
  
.passwordInputField:focus,
.bodyInput:focus {
    outline: none;
}
  
.passwordInputField:disabled,
.bodyInput:disabled {
    cursor: not-allowed;
}

.eyeIcon {
    padding-top: 1px;
    padding-right: 15px;
    border: 1px solid #d3d2d2;
    border-left: none;
    border-radius: 0px 4px 4px 0px;
}

.footer {
    text-align: right;
}

.footer > button + button {
    margin-left: 10px;
}

.tabHeaders {
    display: flex;
    margin-top: 10px;
}

.tabHeaderItem {
    width: 254.35px;
    height: 39px;
    text-align: center;
    padding-top: 7px;
    font-size: 14px;
    color: #6D6D73;
    cursor: pointer;
}

.tabHeaderItemActive {
    background-color: #FFFFFF;
    border-radius: 5px 5px 0px 0px ;
    box-shadow: 2px -2px 5px -2px rgba(0, 0, 0, 0.15);
    color: #008662;
    font-weight: 600;
}

.tabContents {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 0px 5px 5px 5px;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.15);
    height: calc(100vh - 470px);
    padding: 16px;
    overflow-x: auto;
}

.label {
    color: #343a40;
    font-size: 14px;
    line-height: 16.71px;
    margin-top: -10px;
    margin-bottom: 5px;
}