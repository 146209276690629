.header {
  padding-top: 15px;
  padding-bottom: 5px;
  display: flex;
}

.headerText {
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.addButton {
  height: 36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 100;
  padding: 11px 24px;
  color: #ffffff;
  background-color: #008662;
  border: none;
  border-radius: 4px;
  /* width: 165px; */
  white-space: nowrap;
}

.headerSearch {
  padding: 3px 10px;
  width: 325px;
  height: 38px;
  background: #ffffff;
  color: #6d6d73;
  border: 1px solid #ced4da;
  border-right: none;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  outline: none;
  box-shadow: none;
}

.headerSearch::placeholder {
  color: #b1b1b1;
}

.headerSearch:disabled {
  background: #e9ecef;
}

.searchIcon {
  border: 1px solid #ced4da;
  border-left: none;
  height: 38px;
  border-radius: 4px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 3px;
  background: #ffffff;
}

.searchInputSearchIcon:hover {
  content: url('../../../assets/icons/Search-Green.png');
}

.searchInputCloseIcon:hover {
  content: url('../../../assets/icons/Close-Green.png');
}

.headerLabelDiv {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  padding-right: 10px;
  padding-left: 100px;
}

.headerLabel {
  width: 100%;
  padding-top: 8px;
  color: #6d6d73;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
