.views {
  display: flex;
  align-items: stretch;
}

.sidebar {
  width: 257px;
  background-color: #0c212b;
  box-shadow: 10px 4px 20px rgba(0, 0, 0, 0.1);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.vaiShrinkSidebar {
  width: 75px;
}

.content {
  padding: 10px 30px 30px 30px;
  width: 100%;
  background-color: #f6f6f6;
}

.errorPage {
  background-color: #0c212b;
}
