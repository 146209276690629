.textarea {
    width: 100%;
    padding: 8px 13px;
    color: #343a40;
    font-size: 12px;
    line-height: 14.32px;
    border-radius: 4px;
    border: 1px solid #d3d2d2;
}

.textarea {
    resize: none;
    height: 95px;
}

.textarea::placeholder {
    color: #b1b1b1;
}
  
.textarea:focus {
    outline: none;
}
  
.textarea:disabled {
    cursor: not-allowed;
}