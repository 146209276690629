.fc-timegrid-axis{
 background-color: #0c212b;
 color:#fff;
}
th.fc-timegrid-axis{
  width:50px;
 }
.fc-timegrid-slot-label-frame {
  color:#fff;
}
.fc-direction-ltr .fc-timegrid-slot-label-frame{
  text-align: center;
}
.fc-daygrid-event{
background-color: #6D6D73;
}
.fc-timegrid-event{
background-color: #fff;
}
.fc-timegrid-axis-cushion{
  text-align: center;
}
.fc-col-header-cell{
  background-color: #6D6D73;
}

.fc-col-header-cell-cushion{
  color:#fff;
  text-decoration: none;
}
.fc-event-main{
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.fc-toolbar-title{
  visibility: collapse;
}
.fc-today-button{
  visibility: collapse;
}
.fc-scrollgrid tbody{
  height: 100%;
}
.fc-timegrid-event{
  /* min-height: 69px; */
  overflow: hidden;
}
thead>tr>th>div>.fc-scroller{
  overflow: auto !important;
}
.fc .fc-day-disabled {
  background: #45454a !important;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}

.fc-button-primary{
  background-color: #008662 !important;
  border: #008662 !important;
  outline: none;
}
.fc-button-primary:focus{
  border:none;
  outline: none;
}
.fc-button-group:focus{
  border:none;
  outline: none;
}
.fc-button:focus{
  border:none;
  outline: none;
}