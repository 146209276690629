.header {
  padding-top: 15px;
  padding-bottom: 5px;
  display: flex; 
  height: 55px;
}

.headerText {
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.addButton {
  background-color: #b1b1b1;
  font-size: 12px;
  font-weight: 100;
  padding: 7px 12px;
  height: 32px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  text-align: start;
  display: flex;
}

.headerSearch {
  padding: 3px 10px;
  width: 336px;
  height: 38px;
  background: #ffffff;
  color: #6d6d73;
  border: 1px solid #ced4da;
  border-right: none;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  outline: none;
  box-shadow: none;
}

.headerSearch::placeholder {
  color: #b1b1b1;
}

.headerSearch:disabled {
  background: #e9ecef;
}

.searchIcon {
  border: 1px solid #ced4da;
  border-left: none;
  height: 38px;
  border-radius: 4px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 3px;
  background: #ffffff;
}

.searchInputSearchIcon:hover {
  content: url('../../../../assets/icons/Search-Green.png');
}

.searchInputCloseIcon:hover {
  content: url('../../../../assets/icons/Close-Green.png');
}

.divHeaderPadding {
  width: auto;
  padding: 0;
  padding-right: 10px;
  padding-top: 3px;
}
.divHeaderSelect {
  background-color: #008662;
  font-size: 12px;
  font-weight: 100;
  padding: 7px 12px;
  height: 32px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  text-align: start;
  display: flex;
  width: auto;
  margin-right: 8px;
}

.lblText{
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}