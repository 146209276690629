.dateRange, .dateInput, .startDateWrapper, .endDateWrapper {
    width: 100%;
}

.dateRangeWrapper {
    display: flex;
    width: 100%;
}

.dateInput {
    margin-right: 10px;
    margin-top: 5px;
}

.label {
    margin-bottom: 5px;
    font-size: 14px;
    white-space: nowrap;
}

.errorMessage {
    color: #FF7878;
    font-size: 12px;
    margin-top: 3px;
    display: block;
}

.labelLeft {
    display: flex;
}

.labelLeft .label {
    margin-right: 10px;
    margin-top: 12px;
}

.spaceBetween {
    width: 20px;
}